@tailwind base;
@tailwind components;
@tailwind utilities;

/* add your global styles here; use tailwind classes for component/element styles */

@font-face {
	font-family: ITCGaramondStd-LtCond;
	src: url(../fonts/garamond.woff2) format("woff2"),
		url(../fonts/garamond.woff) format("woff");
}

@font-face {
	font-family: OptimaLTPro-BoldItalic;
	src: url(../fonts/optimabolditalic.woff2) format("woff2"),
		url(../fonts/optimabolditalic.woff) format("woff");
}

@font-face {
	font-family: ChicagoBold;
	src: url(../fonts/ChicagoBold.woff2) format("woff2"),
		url(../fonts/ChicagoBold.woff) format("woff");
}

@font-face {
	font-family: OptimaLTPro-Bold;
	src: url(../fonts/optimabold.woff2) format("woff2"),
		url(../fonts/optimabold.woff) format("woff");
}

@font-face {
	font-family: OptimaLTPro-Roman;
	src: url(../fonts/optimaroman.woff2) format("woff2"),
		url(../fonts/optimaroman.woff) format("woff");
}

@font-face {
	font-family: OptimaLTPro;
	src: url(../fonts/optima.woff2) format("woff2"),
		url(../fonts/optima.woff) format("woff");
}

/* .bg-site {
	background-image: url(../img/temp.jpg);
} */

.text-shadow {
	text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);
}

.text-shadow-light {
	text-shadow: 2px 2px 4px rgb(0 0 0 / 20%);
}

.video {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.font-chicago {
	font-smooth: never;
	-webkit-font-smoothing: none;
	-moz-osx-font-smoothing: none;
	-webkit-font-smoothing: antialiased;
}

.content-wrapper {
	background-image: url("../img/content-tile.png");
}

.slick-arrow {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: #fff;
	box-shadow: 2px 2px 4px rgb(0 0 0 / 20%);
	position: absolute;
	top: 50%;
	margin-top: -30px;
	z-index: 40;
	outline: none !important;
	background-position: center center;
	background-repeat: no-repeat;
	text-indent: -999px;
	overflow: hidden;
}

.slick-next {
	right: -30px;
	background-image: url("../img/arrow-right.svg");
}

.slick-prev {
	left: -30px;
	background-image: url("../img/arrow-left.svg");
}

.hidden-important.hidden-important.hidden-important {
	display: none !important;
}

.slick-slider,
.slick-slider .slick-track,
.slick-slider .slick-list,
.slick-slide {
	height: 100% !important;
}

.slick-track {
	display: flex !important;
	justify-content: center;
	align-items: center;
}
